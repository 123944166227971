@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: clip;
  font-family: "Montserrat", sans-serif !important;
  overflow: smooth;
}
.padding-top {
  padding-top: 70px;
}

@media only screen and (max-width: 980px) {
  .padding-top {
    padding-top: 30px;
  }
}
.h2_heading,
.about-mosaic h3.h2_heading,
.section-5 h3.h2_heading,
h4.h2_heading,
h5.h2_heading{
  font-size: 30px;
  font-weight: 700;
  color: #000000;
  position: relative;
  z-index: 999;
}

/********************************************************************************************* navbar ****************************************************************************************/
#header .nav-bg {
  background-color: #23252d;
}
#header .navbar-brand img {
  width: auto;
  height: 60px;
  object-fit: contain;
}
#header .navbar-nav .nav-link {
  align-items: center;
  color: #fff !important;
  display: flex;
  font-size: 16px;
  font-weight: 600;
}
#header .nav-button {
  background-color: #f05a28;
  color: #ffffff;
  border: 1px solid #f05a28;
  padding: 8px 30px;
}
#header .nav-button:hover {
  background-color: transparent;
  color: #f05a28;
}
/* .navbar-nav li{
  margin-bottom: 10px;
} */
@media only screen and (max-width: 1200px) {
  #header .navbar-nav .nav-link {
    margin: 0px 0px;
  }
}
#header #google_translate_element {
  display: flex !important;
  align-items: center !important;
  margin-left: 20px;
}
#header .goog-logo-link {
  display: none !important;
}
#header .goog-te-gadget .goog-te-combo {
  border: none;
}
#header .goog-te-gadget {
  color: transparent !important;
}
#header input::-webkit-outer-spin-button,
#header input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#google_translate_element2 select:focus {
  outline: none;
}
#google_translate_element2 select:focus {
  outline: none;
}
#header .goog-logo-link {
  display: none !important;
}
#header .goog-te-gadget {
  color: transparent !important;
  font-size: 0 !important;
}
#header .goog-te-combo {
  font-size: 14px !important;
  margin: 0 !important;
}
#header .goog-te-banner-frame {
  display: none !important;
}

@media only screen and (max-width: 980px) {
  #header #google_translate_element {
    display: flex !important;
    align-items: center !important;
    margin-left: 0px;
  }
}
#header .modal .modal-content {
  background-color: #f05a28;
  height: 70vh;
  height: 100%;
}
#header .modal .modal-header {
  border: none;
}
#header .modal-body h2 {
  color: #ffffff;
  font-weight: 600;
}
#header .modal-body p {
  color: #ffffff;
}
#header .modal-body .icon {
  color: #ffffff;
}
#header .modal-body .icon-bg {
  color: #ffffff;
  margin-right: 50px;
  /* font-size: 20px; */
  color: #ffffff !important;
  background-color: #f05a28;
  /* padding: 6px; */
  margin-left: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#header .modal-body .icon-bg:hover {
  color: #000 !important;
  background-color: #ffffff;
  /* padding: 6px; */
  font-size: 14px;
  cursor: pointer;
}
#header .modal-body .form-bg {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#header .modal-body form label {
  color: #02044a;
  font-weight: 600;
}
#header .modal-body form button {
  font-size: 14px;
}
#header .modal-body .wrapper {
  margin: 80px 0px;
}
#header .modal-body .form-button {
  background-color: #f05a28;
  border: 1px solid #f05a28;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 5px;
}
#header .modal-body .form-button:hover {
  background-color: transparent;
  color: #f05a28;
}

@media (max-width: 991.98px) {
  #header .modal .modal-content {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
}

/* .modal-backdrop.show {
  opacity: 0 !important;
  z-index: -199999 !important;
} */
.modal-backdrop {
  background-color: transparent !important;
  z-index: -199999 !important;
}
.modal-bg {
  background-color: rgba(0, 0, 0, 0.6);
}

/********************************************************************************************* banner-section ****************************************************************************************/
/* .banner-section .bg {
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)), url('/public/images/banner-2.png');
  height: 60vh;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner-section .text-wrapper {
  position: absolute;
  left: 20%;
  top: 50%;
}
.banner-section .text-wrapper h1 {
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  font-size: 40px;
}

@media only screen and (max-width: 1200px) {
  .banner-section .text-wrapper h1 {
    color: #ffffff;
    font-weight: 700;
    width: 50%;
    font-size: 40px;
  }
}

@media only screen and (max-width: 680px) {
  .banner-section .text-wrapper {
    left: 5%;
  }

  .banner-section .text-wrapper h1 {
    width: 100%;
    font-size: 35px;
  }
} */
.banner-section .bg {
  position: relative;
}
.banner-section .bg-color {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 60vh;
  width: 100%;
  top: 0;
}
.banner-section .bg img {
  height: 60vh;
  width: 100%;
  object-fit: cover;
}
.banner-section .text-wrapper {
  position: absolute;
  left: 20%;
  top: 50%;
  z-index: 999 !important;
}
.banner-section .text-wrapper h1 {
  color: #ffffff !important;
  font-weight: 700;
  width: 100%;
  font-size: 40px;
  z-index: 9999;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .banner-section .text-wrapper h1 {
    color: #ffffff;
    font-weight: 700;
    width: 50%;
    font-size: 40px;
  }
}

@media only screen and (max-width: 680px) {
  .banner-section .text-wrapper {
    left: 5%;
  }

  .banner-section .text-wrapper h1 {
    width: 100%;
    font-size: 35px;
  }
}

/********************************************************************************************* catalogue-section ****************************************************************************************/
.catalogue-section .swiper {
  width: 100%;
  height: 100%;
}
.catalogue-section button {
  background-color: #f05a28;
  color: #ffffff;
  padding: 20px 30px;
  border: 1px solid #f05a28;
}
.catalogue-section button:hover {
  background-color: transparent;
  color: #f05a28;
}
#download .form-button {
  background-color: #f05a28;
  color: #ffffff;
  padding: 5px 30px;
  border: 1px solid #f05a28;
}
.catalogue-section .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.catalogue-section .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalogue-section .swiper-slide .bg-image {
  background:
    url("/public/images/01.jpg");
  height: 700px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.catalogue-section .swiper-slide .bg-image-2 {
  background:
    url("/public/images/02.jpg");
  height: 700px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.catalogue-section .swiper-slide .bg-image-3 {
  background:
    url("/public/images/03.jpg");
  height: 700px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.catalogue-section .swiper-slide .bg-image-4 {
  background:
    url("/public/images/04.jpg");
  height: 700px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.catalogue-section .swiper-slide .bg-image-5 {
  background:
    url("/public/images/05.jpg");
  height: 700px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.catalogue-section .swiper-slide .bg-image-6 {
  background:
    url("/public/images/06.jpg");
  height: 700px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* 
.catalogue-section .swiper-slide .bg-image-4 {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/public/preview/preview_4_800x1200_9_s.jpg");
  height: 700px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.catalogue-section .swiper-slide .bg-image-5 {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/public/preview/preview_5_800x1200_9_s.jpg");
  height: 700px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */

.catalogue-section .swiper-slide .text-wrapper {
  position: absolute;
  bottom: 3%;
  left: 3%;
}
.catalogue-section .swiper-slide .text-wrapper h3 {
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  margin-bottom: 15px;
}
.catalogue-section .swiper-slide .text-wrapper .download-btn {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
  padding: 10px 30px;
  margin-bottom: 20px;
}
.catalogue-section .swiper-slide .text-wrapper .download-btn:hover {
  background-color: transparent;
  color: #000;
}
.catalogue-section .swiper-slide .text-wrapper p {
  color: #ffffff;
  text-align: left;
  width: 60%;
}
.catalogue-section .swiper-pagination {
  position: relative !important;
  margin-top: 20px;
}
.catalogue-section .swiper-pagination-bullet-active {
  background-color: #f05a28 !important;
}
.catalogue-section .swiper-pagination-bullet {
  background-color: #f05a28 !important;
}

@media only screen and (max-width: 980px) {
  .catalogue-section .swiper-slide .text-wrapper p {
    color: #ffffff;
    text-align: left;
    width: 100%;
  }
}

/********************************************************************************************* get-latest-insights ****************************************************************************************/
.get-latest-insights .image-wrapper img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
}
.get-latest-insights .image-wrapper {
  overflow: hidden;
  margin: 0 auto;
}
.get-latest-insights .image-wrapper:hover img {
  transform: scale(1.1);
  cursor: pointer;
}
.get-latest-insights .text-wrapper h5 {
  font-size: 26px;
  font-weight: 600;
}
.get-latest-insights .link {
  color: #000;
  text-decoration: none;
}

@media only screen and (max-width: 980px) {
  .get-latest-insights .image-wrapper img {
    height: 300px;
    width: 100%;
  }
  body nav ul li{
    padding: 0px;
  }
}

/********************************************************************************************* slider ****************************************************************************************/

.slider .swiper {
  width: 100%;
  height: 100%;
}
.slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100px;
  object-fit: contain;
  object-position: center;
}
.slider .swiper-container {
  width: 100%;
}

/* @media screen and (min-width: 640px) {
  .slider .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .slider .swiper-container {
    width: 768px;
  }
} */

/********************************************************************************************* Home ****************************************************************************************/

/********************************************************************************************* section-1 ****************************************************************************************/
/* #home .section-1 .bg {
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)), url('https://images.pexels.com/photos/277559/pexels-photo-277559.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  height: 100vh;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
} */
#home .section-1 .swiper {
  width: 100%;
  height: 100%;
}
#home .section-1 .wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}

/* #home .section-1 .image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
} */

#home .section-1 .swiper-slide img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* background: rgba(0, 0, 0, 0.4); */
}
#home .section-1 .bg-color {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
#home .section-1 .text-wrapper {
  position: absolute;
  left: 20%;
  top: 50%;
}
#home .section-1 .text-wrapper h1,
#home .section-1 .text-wrapper p{
  color: #ffffff;
  font-weight: 700;
  width: 40%;
  font-size: 50px;
  z-index: 999999;
  position: relative;
  line-height: normal;
}
.banner-section .bg .text-wrapper h4{
  font-size: 25px;
  color: #fff;
}

@media only screen and (max-width: 1200px) {
  #home .section-1 .text-wrapper h1 {
    color: #ffffff;
    font-weight: 700;
    width: 50%;
    font-size: 50px;
  }
}

@media only screen and (max-width: 680px) {
  #home .section-1 .text-wrapper {
    left: 5%;
  }

  #home .section-1 .text-wrapper h1 {
    width: 100%;
    font-size: 35px;
  }
}

/********************************************************************************************* section-2 ****************************************************************************************/
#home .section-2 img {
  object-fit: cover;
}
#home .section-2 .wrapper {
  position: relative;
}
#home .section-2 .text-wrapper {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#home .section-2 .wrapper:hover .text-wrapper {
  opacity: 1;
}

@media only screen and (max-width: 680px) {
  #home .section-2 .wrapper .text-wrapper {
    opacity: 1;
  }
}

/********************************************************************************************* section-3 ****************************************************************************************/
.about-mosaic .text-wrapper {
  padding: 0px 40px 0px 30px;
}
.about-mosaic img {
  height: 650px;
  width: 100%;
  object-fit: cover;
}
.about-mosaic a {
  color: #000;
}

@media only screen and (max-width: 1200px) {
  .about-mosaic img {
    height: 700px;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 980px) {
  .about-mosaic .text-wrapper {
    padding: 0px;
  }

  .about-mosaic img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
}

/********************************************************************************************* section-4 ****************************************************************************************/
#home .section-4 .text-wrapper {
  padding: 100px 50px 100px 300px;
}
#home .section-4 .text-wrapper button {
  background-color: #f05a28;
  color: #ffffff;
  padding: 20px 30px;
  border: 1px solid #f05a28;
}
#home .section-4 .text-wrapper button:hover {
  background-color: transparent;
  color: #f05a28;
}
#home .section-4 img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

@media only screen and (max-width: 1600px) {
  #home .section-4 img {
    height: 500px;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1200px) {
  #home .section-4 .text-wrapper {
    padding: 60px 50px 0px 60px;
  }
}

@media only screen and (max-width: 980px) {
  #home .section-4 img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  #home .section-4 .text-wrapper {
    padding: 30px 20px 20px 40px;
  }
}

@media only screen and (max-width: 760px) {
  #home .section-4 .text-wrapper {
    padding: 30px 20px 20px 20px;
  }

  #home .section-4 .text-wrapper button {
    background-color: #f05a28;
    color: #ffffff;
    padding: 10px 20px;
    border: 1px solid #f05a28;
  }
}

/********************************************************************************************* section-5 ****************************************************************************************/
#home .section-5 h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
#home .section-5 .image-wrapper img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
}
#home .section-5 .image-wrapper {
  overflow: hidden;
}
#home .section-5 .image-wrapper:hover img {
  transform: scale(1.1);
  cursor: pointer;
}

@media only screen and (max-width: 980px) {
  #home .section-5 .image-wrapper img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    transition: 0.5s all ease-in-out;
  }
}

/********************************************************************************************* section-6 ****************************************************************************************/
#home .section-6 .bg-color {
  background-color: #f5f5f5;
}
#home .section-6 .image-wrapper {
  overflow: hidden;
}
#home .section-6 .image-wrapper img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
}
#home .section-6 .image-wrapper:hover img {
  transform: scale(1.1);
  cursor: pointer;
}
#home .section-6 p {
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
}
#home .section-6 button {
  background-color: #f05a28;
  padding: 10px 20px;
  color: #ffffff;
  border: 1px solid #f05a28;
  width: 300px;
  font-weight: 500;
}
#home .section-6 button:hover {
  background-color: transparent;
  color: #f05a28;
}

@media only screen and (max-width: 760px) {
  #home .section-6 .image-wrapper img {
    height: 300px;
    width: 100%;
  }

  #home .section-6 button {
    width: 200px;
  }
}

/********************************************************************************************* Compnay ****************************************************************************************/

/********************************************************************************************* section-1 ****************************************************************************************/
#company .section-1 .bg {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/public/preview/preview_7_800x1200_9_s.jpg");
  height: 60vh;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
#company .section-1 .text-wrapper {
  position: absolute;
  left: 20%;
  top: 50%;
}
#company .section-1 .text-wrapper h1 {
  color: #ffffff;
  font-weight: 700;
  font-size: 50px;
}

@media only screen and (max-width: 1200px) {
  #company .section-1 .text-wrapper h1 {
    color: #ffffff;
    font-weight: 700;
    font-size: 50px;
  }
}

@media only screen and (max-width: 680px) {
  #company .section-1 .text-wrapper {
    left: 5%;
  }

  #company .section-1 .text-wrapper h1 {
    font-size: 35px;
  }
}

/********************************************************************************************* section-2 ****************************************************************************************/
#company .section-2 .text-wrapper {
  padding: 0px 50px 0px 300px;
}
#company .section-2 img {
  width: 100%;
  height: 650px;
  object-fit: cover;
}

@media only screen and (max-width: 1600px) {
  #company .section-2 .text-wrapper {
    padding: 0px 50px 0px 100px;
  }
}

@media only screen and (max-width: 1200px) {
  #company .section-2 .text-wrapper {
    padding: 0px 50px 0px 60px;
  }
}

@media only screen and (max-width: 980px) {
  #company .section-2 img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  #company .section-2 .text-wrapper {
    padding: 10px 20px 0px 40px;
  }
}

@media only screen and (max-width: 760px) {
  #company .section-2 .text-wrapper {
    padding: 0px 20px;
  }
}

/********************************************************************************************* section-3 ****************************************************************************************/
#company .section-3 {
  background-color: #f7f7f7;
  padding: 100px 0px;
}
#company .section-3 .icon {
  font-size: 65px;
  transition: 300ms ease-in-out;
}
#company .section-3 .icon:hover {
  font-size: 75px;
  color: #f05a28;
  transform: scale(1.1);
}
#company .section-3 p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
}

@media only screen and (max-width: 980px) {
  #company .section-3 {
    background-color: #f7f7f7;
    padding: 30px 0px;
  }
}

/********************************************************************************************* section-4 ****************************************************************************************/
#company .section-4 .text-wrapper {
  padding: 0px 300px 0px 50px;
}
#company .section-4 img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

@media only screen and (max-width: 1600px) {
  #company .section-4 .text-wrapper {
    padding: 0px 100px 0px 50px;
  }
}

@media only screen and (max-width: 1200px) {
  #company .section-4 .text-wrapper {
    padding: 0px 60px 0px 50px;
  }
}

@media only screen and (max-width: 980px) {
  #company .section-4 img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  #company .section-4 .text-wrapper {
    padding: 10px 40px 0px 20px;
  }
}

@media only screen and (max-width: 760px) {
  #company .section-4 .text-wrapper {
    padding: 0px 20px;
  }
}

/********************************************************************************************* section-6 ****************************************************************************************/
#company .section-5 h6 {
  font-size: 20px;
  font-weight: 600;
}
#company .section-6 img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 760px) {
  #company .section-6 img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
}

/********************************************************************************************* section-7 ****************************************************************************************/
#company .section-7 .bg-color {
  background-color: #f9f9f9;
}

/********************************************************************************************* Media ****************************************************************************************/

/********************************************************************************************* section-2 ****************************************************************************************/

#media .section-2 .text-wrapper {
  padding: 70px 100px 0px 30px;
}
#media .section-2 img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 780px) {
  #media .section-2 .text-wrapper {
    padding: 0px;
  }

  #media .section-2 img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
}

/********************************************************************************************* section-3 ****************************************************************************************/

#media .section-3 .nav-pills .nav-link.active,
#media .section-3 .nav-pills .show>.nav-link {
  color: #f05a28 !important;
  background-color: transparent !important;
  border-bottom: 1px solid #f05a28;
  border-radius: 0px !important;
}
#media .section-3 .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #f05a28;
  background-color: transparent !important;
}
#media .section-3 .nav-link {
  color: #000000 !important;
  font-weight: 500;
  margin-left: 10px;
}
#media .section-3 .tab-content .image-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}
#media .section-3 .tab-content .image {
  display: block;
  width: 100%;
  height: 400px;
  transition: 0.5s all ease-in-out;
}
#media .section-3 .tab-content .overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}
#media .section-3 .tab-content .image-wrapper:hover .overlay {
  bottom: 0;
  height: 100%;
  width: 100%;
}
#media .section-3 .tab-content .text {
  color: #f05a28;
  font-size: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
#media .section-3 .tab-content .text:hover {
  background-color: #f05a28;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
#media .section-3 .image-wrapper:hover .image {
  transform: scale(1.2);
  cursor: pointer;
}

/********************************************************************************************* Blogs ****************************************************************************************/

/********************************************************************************************* section-2 ****************************************************************************************/
#blogs .section-2 .bg-color {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}
#blogs .section-2 .image-wrapper {
  overflow: hidden;
  border-radius: 5px;
}
#blogs .section-2 .image-wrapper img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
  border-radius: 5px;
}
#blogs .section-2 .image-wrapper:hover img {
  transform: scale(1.2);
  cursor: pointer;
}
#blogs .section-2 small {
  color: #f05a28;
}
#blogs .section-2 .blog-button {
  background-color: #f05a28;
  border: 1px solid #f05a28;
  padding: 10px 30px;
  color: #ffffff;
  /* border: none;
  background-color: transparent; */
}
#blogs .section-2 .blog-button:hover {
  background-color: transparent;
  color: #f05a28;
}

/********************************************************************************************* Products ****************************************************************************************/

/********************************************************************************************* section-2 ****************************************************************************************/
#products .section-2 img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
}
#products .section-2 h6 {
  font-size: 22px;
  margin: 10px 0px;
  font-weight: 600;
}
#products .section-2 .image-wrapper {
  overflow: hidden;
  margin: 0 auto;
}
#products .section-2 .image-wrapper:hover img {
  transform: scale(1.1);
}
#products .section-2 .nav-pills .nav-link.active,
#products .section-2 .nav-pills .show>.nav-link {
  color: #f05a28 !important;
  background-color: transparent !important;
  border-bottom: 1px solid #f05a28;
  border-radius: 0px !important;
}
#products .section-2 .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #f05a28;
  background-color: transparent !important;
}
#products .section-2 .nav-link {
  color: #000000 !important;
  font-weight: 500;
  margin-left: 10px;
}
#blog-inside p {
  color: #595d62;
  font-size: 1.25rem;
  line-height: 33px;
  margin-top: 20px;
}
#blog-inside h2 {
  font-size: 30px;
  line-height: 40px;
  color: #131314;
  font-weight: 500;
  margin-top: 40px;
}
#blog-inside ol {
  list-style-type: none;
}
#blog-inside ol li h4 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
}
#blog-inside .table-of-content {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 10px 20px;
  border-radius: 5px;
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  /* margin-left: 30px; */
}
#blog-inside .table-of-content ul li,
#blog-inside .table-of-content ul li a {
  font-size: 18px;
  color: #595d62;
  line-height: 28px;
  text-decoration: none;
  /* list-style-type:decimal; */
}
#blog-inside .pros-cons {
  /* font-family: "Raleway", sans-serif; */
  display: flex;
  max-width: 700px;
  margin: 32px auto;
  box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.4);
}
#blog-inside .pros-cons .heading {
  font-size: 20px;
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 16px 24px;
}
#blog-inside .pros-cons .pros-container .heading {
  background: #f05a28;
}
#blog-inside .pros-cons .cons-container .heading {
  background: #e63946;
}
#blog-inside .pros-cons .pros-container ul li::before {
  /* content: "\f00c"; */
  /* font-family: "Font Awesome 5 Free"; */
  font-weight: bold;
  padding-right: 16px;
  color: #02c39a;
  display: block;
}
#blog-inside .pros-cons .cons-container ul li::before {
  /* content: "\f00d"; */
  /* font-family: "Font Awesome 5 Free"; */
  font-weight: bold;
  padding-right: 16px;
  color: #e63946;
  display: block;
}
#blog-inside .pros-cons .pros-container,
#blog-inside .pros-cons .cons-container {
  flex: 1;
}
#blog-inside .pros-cons .pros {
  border-right: 1px solid #eee;
}
#blog-inside .pros-cons ul {
  padding: 8px 0;
  list-style: none;
  margin: 0;
}
#blog-inside .pros-cons ul li {
  padding: 16px 32px;
  font-size: 16px;
  line-height: 2;
  display: flex;
}

@media (max-width: 530px) {
  #blog-inside .pros-cons {
    flex-direction: column;
  }

  #blog-inside .pros-cons ul li:nth-of-type(2n) {
    background: #eee;
  }
}

@media only screen and (max-width: 780px) {
  #blog-inside h1 {
    font-size: 34px;
    line-height: 40px;
  }

  #blog-inside h2 {
    font-size: 26px;
    line-height: 35px;
    color: #131314;
    font-weight: 500;
    margin-top: 40px;
  }

  #blog-inside .col-xl-4 {
    float: left !important;
  }
}

/********************************************************************************************* contact ****************************************************************************************/
#contact form input {
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0px;
}
#contact form textarea {
  border-radius: 0px;
  height: 120px;
  border: none;
  border-bottom: 1px solid #ced4da;
}
#contact form input:focus {
  border-color: #f05a28;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#contact form textarea:focus {
  border-color: #f05a28;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#contact form button {
  background-color: #f05a28;
  padding: 10px 50px;
  color: #fff;
  border: 2px solid #f05a28;
}
#contact form button:hover {
  background-color: transparent;
  padding: 10px 50px;
  color: #f05a28;
}
#contact form input:focus+label {
  color: #f05a28;
}
#contact form textarea:focus+label {
  color: #f05a28;
}
#contact .icon-bg {
  background-color: #fff;
  color: #f05a28;
  border: 1px solid #f05a28;
  padding: 50px 10px;
  min-height: 300px;
}
#contact .icon-bg:hover {
  background-color: #f05a28;
  color: #fff;
  cursor: pointer;
}
#contact .icon-bg:hover p {
  color: #ffffff;
}
#contact .icon-bg:hover a {
  color: #ffffff;
}
#contact .icon-bg .icon {
  font-size: 36px;
}
#contact .icon-bg p {
  color: #000;
}
#contact .icon-bg h6 {
  font-size: 26px;
}
#contact .icon-bg a {
  color: #000;
  text-decoration: none;
}

@media only screen and (max-width: 980px) {
  #contact .icon-bg {
    background-color: #fff;
    color: #f05a28;
    border: 1px solid #f05a28;
    padding: 20px 10px;
    min-height: 300px;
  }
}

@media only screen and (max-width: 720px) {
  #contact .icon-bg {
    background-color: #fff;
    color: #f05a28;
    border: 1px solid #f05a28;
    padding: 20px 10px;
    min-height: 300px;
  }
}

/*********************************************************************************************  TESTIMONIALS ****************************************************************************************/
.testimonials .swiper {
  width: 100%;
  height: 100%;
}
.testimonials .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.testimonials .swiper-slide img {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.testimonials .swiper-slide h6 {
  margin: 30px 0px;
  font-weight: 600;
}
.testimonials .swiper-slide p {
  font-weight: 600;
  font-size: 18px;
  width: 80%;
}
.testimonials .swiper-button-next,
.testimonials .swiper-button-prev {
  color: #f05a28 !important;
}

@media only screen and (max-width: 680px) {
  .testimonials .swiper-slide p {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
  }

  .testimonials .swiper-button-next,
  .testimonials .swiper-button-prev {
    display: none !important;
  }
}

/*********************************************************************************************  FAQ ****************************************************************************************/

#faq .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #000;
}
#faq .accordion-button:focus {
  box-shadow: none;
  border-color: none;
}
#faq .accordion-header {
  border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
#faq .accordion-header .accordion-button {
  font-size: 20px;
}
#faq .accordion-body p {
  color: #595d62;
  font-size: 18px;
  line-height: 28px;
}
#faq .accordion-body h5 {
  font-size: 20px;
  font-weight: 500;
  color: #595d62;
}
#faq .accordion-body ul li {
  font-size: 18px;
  margin: 10px 0px;
}
#faq .accordion-item {
  border-bottom: none;
}
#faq .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(0.7) !important;
}
#faq .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

@media only screen and (max-width: 680px) {
  #faq .accordion-header .accordion-button {
    font-size: 16px;
  }

  #faq .accordion-body p {
    color: #595d62;
    font-size: 14px;
    line-height: 20px;
  }

  #faq .accordion-body h5 {
    font-size: 16px;
    font-weight: 500;
    color: #595d62;
  }

  #faq .accordion-body ul li {
    font-size: 14px;
    margin: 10px 0px;
  }
}

/*********************************************************************************************  Download ****************************************************************************************/
#download .section-2 .image-wrapper img {
  height: 600px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  transition: 0.5s all ease-in-out;
}
#download .section-2 #mosaic .image-wrapper img {
  height: 250px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  transition: 0.5s all ease-in-out;
}


#download .section-2 #porcelain-tiles .image-wrapper img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.5s all ease-in-out;
}
#download .section-2 #porcelain-slab .image-wrapper img {
  height: 250px;
  width: 100%;
  object-fit: contain;
  object-position: top center;
  transition: 0.5s all ease-in-out;
}
#download .section-2 #outdoor .image-wrapper img {
  height: 300px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  transition: 0.5s all ease-in-out;
}


#download .section-2 #sintered-stone .image-wrapper img {
  height: 600px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  transition: 0.5s all ease-in-out;
}
#download .section-2 .image-wrapper {
  overflow: hidden;
  margin: 0 auto;
  /* background-color: #F5F5F5; */
}
#download .section-2 .wrapper {
  border: 2px solid #f05a28;
}
#download .section-2 .image-wrapper:hover img {
  transform: scale(1.1);
  cursor: pointer;
}
#download .section-2 h6 {
  font-size: 20px;
  margin: 20px 0px;
  /* color: #F05A28; */
  font-weight: 600;
}
#download .section-2 .download-btn {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
  padding: 10px 30px;
  margin-bottom: 20px;
}
#download .section-2 .download-btn:hover {
  background-color: transparent;
  color: #000;
}


#download .section-2 .nav-pills .nav-link.active,
#download .section-2 .nav-pills .show>.nav-link {
  color: #000 !important;
  background-color: #f05a28 !important;
  border-bottom: 1px solid #f05a28;
  border-radius: 0px !important;
}
#download .section-2 .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff !important;
  background-color: #f05a28 !important;
}
#download .section-2 .nav-link {
  color: #000000;
  font-weight: 500;
  margin-left: 10px;
  /* padding: 10px 30px; */
  margin-bottom: 30px;
}

@media only screen and (max-width: 1400px) {
  #download .section-2 #porcelain-slab .image-wrapper img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: top center;
    transition: 0.5s all ease-in-out;
  }

}

@media only screen and (max-width: 769px) {
  #download .section-2 #porcelain-slab .image-wrapper img {
    height: 350px;
    width: 100%;
    object-fit: cover;
    object-position: top center;
    transition: 0.5s all ease-in-out;
  }

}

/*********************************************************************************************  404 ****************************************************************************************/
#page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
#page-not-found h1 {
  font-size: 150px;
  color: #f05a28;
  font-weight: 700;
}
#page-not-found p {
  font-size: 20px;
  font-weight: 500;
}
#page-not-found button {
  background-color: #f05a28;
  padding: 10px 30px;
  border-radius: 20px;
  border: 2px solid #f05a28;
  color: #fff;
}
#page-not-found button:hover {
  background-color: transparent;
  color: #f05a28;
}

@media only screen and (max-width: 680px) {
  #page-not-found h1 {
    font-size: 50px;
    color: #f05a28;
    font-weight: 700;
  }
}

/*********************************************************************************************  footer ****************************************************************************************/
footer .bg-color {
  background-color: #23252d;
  padding: 20px 20px;
  color: #ffffff;
}

footer .logo-image {
  width: 184px;
  height: auto;
  object-fit: fill;
}

footer h6 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}

footer p,
address {
  font-size: 13px;
  font-weight: 300;
}

footer a {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
}

footer a:hover {
  color: #f05a28;
  transition: all 0.3s ease-in-out;
}

footer .row .col-lg-2 {
  /* padding-top: 80px; */
}

footer .row .col-lg-2 h4 {
  font-size: 22px;
  padding-bottom: 10px;
}

footer .row .col-lg-4:last-child {
  /* padding-top: 80px; */
}

footer .social-icons {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0px;
  /* column-gap: 10px; */
}

footer .social-icons .icon-bg {
  color: #ffffff;
  background-color: #727271;
  padding: 6px;
  font-size: 12px;
  /* margin-left: 10px; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

footer .social-icons .icon-bg:hover {
  background-color: #f05a28;
  transition: all 0.3s ease-in-out;
}

footer .social-icons .icon-bg a {
  font-size: 18px;
  color: #fff !important;
}

@media only screen and (max-width: 680px) {
  footer .social-icons {
    background-color: #f5f5f5;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  footer .social-icons .icon-bg {
    color: #ffffff;
    background-color: #727271;
    padding: 0px;
    font-size: 12px;
    margin-left: 10px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#preloader {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
}
#preloader img {
  height: 60px;
  object-position: center;
  object-fit: contain;
  width: 100%;
}

/* .text-bg video {
  height: 400px;
  object-position: center;
  object-fit: contain;
  width: 100%;
} */
/*******************************  social-icons ******************************/
#social-icons button {
  border: none;
  background-color: #000;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border: 10px solid #f05a28;
  color: #fff;
  position: fixed;
  bottom: 1%;
  right: 1%;
  z-index: 9999;
}
#social-icons .wrapper .icons {
  position: fixed;
  bottom: 9%;
  right: 1%;
  z-index: 9999 !important;
  background-color: #000;
  padding: 20px;
  width: 250px;
  border-radius: 6px;
}
#social-icons .wrapper .icons .social {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}
#social-icons .wrapper .icons .social p {
  display: inline;
  color: #fff;
  margin-left: 15px;
}
#social-icons .wrapper .icons .social img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  object-position: center;
}
#social-icons .swiper {
  width: 100%;
  height: 100%;
}
#social-icons .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}




.video-container {
  width: 100%;
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 8px 20px rgba(black, 0.4);
  height: 400px;
}
.video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-container video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.play-button-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  pointer-events: none;

}
.play-button-wrapper #circle-play-b {
  cursor: pointer;
  pointer-events: auto;

}
.play-button-wrapper #circle-play-b svg {
  width: 100px;
  height: 100px;
  fill: #fff;
  stroke: #fff;
  cursor: pointer;
  background-color: rgba(black, 0.2);
  border-radius: 50%;
  opacity: 0.9;
}


.image-effect-one {
  background-color: #000;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}
.image-effect-one * {
  /* -webkit-box-sizing: border-box; */
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.image-effect-one:before,
.image-effect-one:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  background-color: #000000;
  content: '';
  opacity: 0.6;
  z-index: 1;
}
.image-effect-one:before {
  -webkit-transform: skew(40deg) translateX(-155%);
  transform: skew(40deg) translateX(-155%);
}
.image-effect-one:after {
  -webkit-transform: skew(40deg) translateX(155%);
  transform: skew(40deg) translateX(155%);
}
.image-effect-one img {
  backface-visibility: hidden;
  max-width: 100%;
  width: 100%;
  vertical-align: top;
}
.image-effect-one .figcaption {
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%) scale(0.5);
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  -webkit-box-shadow: 0 0 10px #000000;
  box-shadow: 0 0 10px #000000;
}
.image-effect-one .mfp-link {
  background-color: #000000;
  border: 2px solid #959595;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  padding: 5px 10px;
  text-transform: uppercase;
}
.image-effect-one:hover>img {
  opacity: 0.5;
}
.image-effect-one:hover:before {
  -webkit-transform: skew(40deg) translateX(-55%);
  transform: skew(40deg) translateX(-55%);
}
.image-effect-one:hover:after {
  -webkit-transform: skew(40deg) translateX(55%);
  transform: skew(40deg) translateX(55%);
}
.image-effect-one:hover .figcaption {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.project-mas p {
  margin-bottom: 0px;
}
.mfp-bg,
.mfp-wrap {
  position: fixed;
  left: 0;
  top: 0
}
.mfp-bg,
.mfp-container,
.mfp-wrap {
  height: 100%;
  width: 100%
}
.mfp-arrow:after,
.mfp-arrow:before,
.mfp-container:before,
.mfp-figure:after {
  content: ''
}
.mfp-bg {
  z-index: 1042;
  overflow: hidden;
  background: #0b0b0b;
  opacity: .8
}
.mfp-wrap {
  z-index: 1043;
  outline: 0 !important;
  -webkit-backface-visibility: hidden
}
.mfp-container {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box
}
.mfp-container:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle
}
.mfp-align-top .mfp-container:before {
  display: none
}
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045
}
.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
  width: 100%;
  cursor: auto
}
.mfp-ajax-cur {
  cursor: progress
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in
}
.mfp-auto-cursor .mfp-content {
  cursor: auto
}
.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}
.mfp-loading.mfp-figure {
  display: none
}
.mfp-hide {
  display: none !important
}
.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -.8em;
  left: 8px;
  right: 8px;
  z-index: 1044
}
.mfp-preloader a {
  color: #CCC
}
.mfp-close,
.mfp-preloader a:hover {
  color: #FFF
}
.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
  display: none
}

button.mfp-arrow,
button.mfp-close {
  overflow: visible;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: 0;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation
}
.mfp-figure:after,
.mfp-iframe-scaler iframe {
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  position: absolute;
  left: 0
}

button::-moz-focus-inner {
  padding: 0;
  border: 0
}
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: .65;
  padding: 0 0 18px 10px;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace
}
.mfp-close:focus,
.mfp-close:hover {
  opacity: 1
}
.mfp-close:active {
  top: 1px
}
.mfp-close-btn-in .mfp-close {
  color: #333
}
.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%
}
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap
}
.mfp-figure,
img.mfp-img {
  line-height: 0
}
.mfp-arrow {
  position: absolute;
  opacity: .65;
  margin: -55px 0 0;
  top: 50%;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent
}
.mfp-arrow:active {
  margin-top: -54px
}
.mfp-arrow:focus,
.mfp-arrow:hover {
  opacity: 1
}
.mfp-arrow:after,
.mfp-arrow:before {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: inset transparent
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: .7
}
.mfp-arrow-left {
  left: 0
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F
}
.mfp-arrow-right {
  right: 0
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F
}
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px
}
.mfp-image-holder .mfp-content,
img.mfp-img {
  max-width: 100%
}
.mfp-iframe-holder .mfp-close {
  top: -40px
}
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%
}
.mfp-iframe-scaler iframe {
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000
}
.mfp-figure:after,
img.mfp-img {
  width: auto;
  height: auto;
  display: block
}

img.mfp-img {
  box-sizing: border-box;
  padding: 40px 0;
  margin: 0 auto
}
.mfp-figure:after {
  top: 40px;
  bottom: 40px;
  right: 0;
  z-index: -1;
  background: #444
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px
}
.mfp-figure figure {
  margin: 0
}
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto
}
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px
}
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer
}

@media screen and (max-width: 800px) and (orientation:landscape),
screen and (max-height:300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, .6);
    position: fixed;
    text-align: center;
    padding: 0
  }
}


@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(.75);
    transform: scale(.75)
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(.75);
    transform: scale(.75)
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px
  }
}
.image-effect-one .mfp-link {
  border-color: rgb(240, 90, 40);
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa {
  display: inline-block;
  /* font: normal normal normal 14px/1 FontAwesome; */
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* changes gallary */

/* styles.css */
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.gallery-image {
  width: 200px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}

/* New-one css */
#container {
  margin: 0 auto;
}

nav {
  /* background-color: #E64A19; */
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  display: inline-block;
  /* background-color: #E64A19; */
  padding: 15px 0px;
}

nav a {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #FFF;
  line-height: normal;
  text-decoration: none;
}

nav a:hover {
  color: #f05a28;
}

nav ul ul {
  display: none;
  position: absolute;
  top: 100%;
}

nav ul li:hover>ul {
  display: inherit;
  /* padding-top: 10px; */
}

nav ul ul li {
  width: 250px;
  float: none;
  display: list-item;
  position: relative;
  background-color: #1d1813;
}

nav ul ul ul li {
  position: relative;
  top: -60px;
  left: 100%;
}

ul ul li>a:after {
  content: ' + ';
  margin-left: 20px;
  font-size: 25px;
}

li>a:only-child:after {
  content: '';
}
.navbar-nav {
  align-items: center;
}

ul.notfound-name {
  padding-left: 0px;
  line-height: 1.9;
}

ul.notfound-name li {
  list-style: none;
}

/* div li a.new-one-some a.nav-link::before{
  display: none;
} */

/* product page */
.mpt-50 {
  padding-top: 50px !important;
}
.spb-80 {
  padding-bottom: 50px;
}
.img_div{
  height: 600px;
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
}
.img_div img{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
}
body .loader{
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50% , -50%) !important;
}
.text-wrapper h6{
  padding-top: 8px;
}
.mple{
  padding-left: 5px;
}
h3.font-size{
  font-size: 32px;
  color: white;
  font-weight: 600;
}
.footer-logo{
  text-align: center;
  padding: 30px;
  background: #23252d;
  border-bottom: 2px solid #5a5c62;
}
/* .email_div_footer{
  display: flex;
  flex-wrap: wrap;
} */
.email_div_footer svg{
  font-size: 24px;
  margin-bottom: 10px;
}
footer .email-links a{
  font-size: 16px;
}
.contact-div svg{
  font-size: 24px;
  margin-top: 25px;
  margin-bottom: 10px;
}
footer .contact-now a{
  font-size: 16px;
}
.copy_right_div p{
  font-size: 14px;
  font-weight: 400;
}
.copy_right_div p a{
  font-weight: 600;
}
.new-display-other{
  display: flex;
  align-items: center;
}